import { rest, RestHandler, MockedRequest, DefaultBodyType } from 'msw'
import { GetExampleResponse } from '@/repositories/exampleRepository'

// note: prefixの定義は読み込まれた時点で実行される。
// const prefix =
//   process?.env === undefined
//     ? '/mock'
//     : // playwrightテスト用に、疑似的なURLを使用する
//       // https://mswjs.io/docs/getting-started/integrate/node#direct-usage
//       'http://mock'

export const handlers: readonly RestHandler<MockedRequest<DefaultBodyType>>[] =
  [
    rest.get(
      // NOTE: 実際のAPIの結果を返す時、ctx.statusに response.status を直接わたすと 'ok' が入ってしまう場合エラーになる
      //       response.status === 'ok' ? 200 : 400 とかを渡すこと

      // NOTE: ここで process.envが使われると実行時エラーになる...
      // `${process?.env === undefined ? '/mock' : 'http://mock'}/example`,

      `/mock/example`,
      (_req, res, ctx) => {
        const data: GetExampleResponse = {
          status: 'ok',
          data: {
            todos: [
              {
                userId: 0,
                id: 0,
                title: 'Do something!',
                completed: true,
              },
              {
                userId: '1',
                id: '1',
                title: 'Say hello',
                completed: false,
              },
            ],
          },
        }
        return res(ctx.status(200), ctx.json(data))
      }
    ),
  ]
